import React from 'react';
import noSalesImage from "../images/no-sales-made.png";
import EmptyComponent from "../components/Empty";

const Sales = () => {
	const component = <EmptyComponent
		imageSrc={noSalesImage}
		headerText={"Sales"}
		descriptionText={"You have made no sales yet!"}
	/>
	return (
		<div className="flex flex-col items-center justify-center min-h-screen rounded dark:bg-gray-900">
			{ component }
		</div>
	);
};

export default Sales;
