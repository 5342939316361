import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/solid';
import { statusesFromJSON, Collection, Statuses, Sources, Categories, StockLevel, QuantitySizing, Inventory } from './types';

const steps = [
	{ id: '01', name: 'Basic Info', status: 'complete' },
	{ id: '02', name: 'Purchase Details', status: 'current' },
	{ id: '03', name: 'Classification Info', status: 'upcoming' },
];

const initialInventory: Inventory = {
	name: '',
	SKU: '',
	brand: '',
	color: '',
	totalQuantity: 0,
	description: '',
	sizes: [],
	purchaseDetails: undefined,
	category: Categories.UNRECOGNIZED,
	status: Statuses.UNLISTED,
	source: Sources.UNRECOGNIZED,
	platform: -1, // Assuming MarketplacePlatform is an enum
	deleted: false,
	createdAt: Date.now(),
	updatedAt: Date.now(),
	collectionId: '',
	outOfStock: false,
	stockLevel: StockLevel.UNRECOGNIZED,
	id: '',
	user: '',
	scheduleListing: 0,
	autoList: false,
};

const defaultCategory = (name: string): Categories => {
	if (name.toLowerCase().includes('sneaker')) return Categories.SNEAKERS;
	if (name.toLowerCase().includes('shirt')) return Categories.TOPS;
	// Add more conditions as needed
	return Categories.UNRECOGNIZED;
};

const calculateTotalQuantity = (sizes: QuantitySizing[]): number => {
	return sizes.reduce((total, size) => total + size.quantity, 0);
};

const determineStockLevel = (totalQuantity: number): StockLevel => {
	if (totalQuantity < 10) return StockLevel.LOW;
	if (totalQuantity >= 10 && totalQuantity <= 50) return StockLevel.NORMAL;
	return StockLevel.HIGH;
};

const InventoryForm = ({ initialData = initialInventory, onClose }: { initialData?: Inventory, onClose: () => void; }) => {
	const [inventory, setInventory] = useState<Inventory>(initialData);
	const [currentStep, setCurrentStep] = useState(0);
	const [loading, setLoading] = useState(false);
	const [showDialog, setShowDialog] = useState(true);

	const handleNextStep = () => {
		setCurrentStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
	};

	const handlePreviousStep = () => {
		setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setInventory((prevInventory: Inventory) => ({
			...prevInventory,
			[name]: value,
		}));
	};

	const handleSizeChange = (index: number, field: string, value: string | number) => {
		const newSizes = [...inventory.sizes];
		newSizes[index] = { ...newSizes[index], [field]: value };
		setInventory((prevInventory: Inventory) => ({
			...prevInventory,
			sizes: newSizes,
			totalQuantity: calculateTotalQuantity(newSizes),
			stockLevel: determineStockLevel(calculateTotalQuantity(newSizes)),
			outOfStock: newSizes.length === 0 || calculateTotalQuantity(newSizes) === 0,
		}));
	};

	const handleAddSize = () => {
		setInventory((prevInventory: Inventory) => ({
			...prevInventory,
			sizes: [...prevInventory.sizes, { size: '', quantity: 0 }],
		}));
	};

	const handleRemoveSize = (index: number) => {
		const newSizes = inventory.sizes.filter((_:any, i:any) => i !== index);
		setInventory((prevInventory: Inventory) => ({
			...prevInventory,
			sizes: newSizes,
			totalQuantity: calculateTotalQuantity(newSizes),
			stockLevel: determineStockLevel(calculateTotalQuantity(newSizes)),
			outOfStock: newSizes.length === 0 || calculateTotalQuantity(newSizes) === 0,
		}));
	};

	const handleFormSubmit = async () => {
		setLoading(true);
		try {
			// Simulate API call
			await new Promise((resolve) => setTimeout(resolve, 2000));
			console.log('Inventory created:', inventory);
			setShowDialog(false);
		} catch (error) {
			console.error('Error creating inventory:', error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Transition appear show={showDialog} as={React.Fragment}>
			<Dialog as="div" className="relative z-10" onClose={() => {setShowDialog(false); onClose();}}>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={React.Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-3xl transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
								<nav aria-label="Progress">
									<ol role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
										{steps.map((step, stepIdx) => (
											<li key={step.name} className="relative md:flex md:flex-1">
												{step.status === 'complete' ? (
													<a href="#" className="group flex w-full items-center">
                            <span className="flex items-center px-6 py-4 text-sm font-medium">
                              <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                                <CheckIcon aria-hidden="true" className="h-6 w-6 text-white" />
                              </span>
                              <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                            </span>
													</a>
												) : step.status === 'current' ? (
													<a href="#" aria-current="step" className="flex items-center px-6 py-4 text-sm font-medium">
                            <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                              <span className="text-indigo-600">{step.id}</span>
                            </span>
														<span className="ml-4 text-sm font-medium text-indigo-600">{step.name}</span>
													</a>
												) : (
													<a href="#" className="group flex items-center">
                            <span className="flex items-center px-6 py-4 text-sm font-medium">
                              <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                                <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                              </span>
                              <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</span>
                            </span>
													</a>
												)}

												{stepIdx !== steps.length - 1 ? (
													<>
														{/* Arrow separator for lg screens and up */}
														<div aria-hidden="true" className="absolute right-0 top-0 hidden h-full w-5 md:block">
															<svg
																fill="none"
																viewBox="0 0 22 80"
																preserveAspectRatio="none"
																className="h-full w-full text-gray-300"
															>
																<path
																	d="M0 -2L20 40L0 82"
																	stroke="currentcolor"
																	vectorEffect="non-scaling-stroke"
																	strokeLinejoin="round"
																/>
															</svg>
														</div>
													</>
												) : null}
											</li>
										))}
									</ol>
								</nav>

								<form onSubmit={(e) => { e.preventDefault(); handleFormSubmit(); }}>
									{currentStep === 0 && (
										<div className="mt-4">
											<h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">Basic Info</h3>
											<div className="mt-2">
												<label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
													Name
												</label>
												<input
													type="text"
													name="name"
													id="name"
													value={inventory.name}
													onChange={handleInputChange}
													className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
												/>
											</div>
											<div className="mt-2">
												<label htmlFor="SKU" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
													SKU
												</label>
												<input
													type="text"
													name="SKU"
													id="SKU"
													value={inventory.SKU}
													onChange={handleInputChange}
													className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
												/>
											</div>
											<div className="mt-2">
												<label htmlFor="brand" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
													Brand
												</label>
												<input
													type="text"
													name="brand"
													id="brand"
													value={inventory.brand}
													onChange={handleInputChange}
													className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
												/>
											</div>
											<div className="mt-2">
												<label htmlFor="color" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
													Color
												</label>
												<input
													type="text"
													name="color"
													id="color"
													value={inventory.color}
													onChange={handleInputChange}
													className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
												/>
											</div>
											<div className="mt-2">
												<label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
													Description
												</label>
												<textarea
													name="description"
													id="description"
													value={inventory.description}
													onChange={handleInputChange}
													className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
												/>
											</div>
											<div className="mt-2">
												<label htmlFor="sizes" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
													Sizes
												</label>
												{inventory.sizes.map((size:any, index:any) => (
													<div key={index} className="flex items-center mt-1">
														<input
															type="text"
															name={`size-${index}`}
															value={size.size}
															onChange={(e) => handleSizeChange(index, 'size', e.target.value)}
															className="mr-2 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
															placeholder="Size"
														/>
														<input
															type="number"
															name={`quantity-${index}`}
															value={size.quantity}
															onChange={(e) => handleSizeChange(index, 'quantity', parseInt(e.target.value))}
															className="mr-2 block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
															placeholder="Quantity"
														/>
														<button
															type="button"
															onClick={() => handleRemoveSize(index)}
															className="text-red-500 hover:text-red-700"
														>
															Remove
														</button>
													</div>
												))}
												<button
													type="button"
													onClick={handleAddSize}
													className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
												>
													Add Size
												</button>
											</div>
										</div>
									)}

									{currentStep === 1 && (
										<div className="mt-4">
											<h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">Purchase Details</h3>
											<div className="mt-2">
												<label htmlFor="price" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
													Price
												</label>
												<input
													type="number"
													name="price"
													id="price"
													value={inventory.purchaseDetails?.price || ''}
													onChange={handleInputChange}
													className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
												/>
											</div>
											<div className="mt-2">
												<label htmlFor="shipping" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
													Shipping
												</label>
												<input
													type="text"
													name="shipping"
													id="shipping"
													value={inventory.purchaseDetails?.shipping || ''}
													onChange={handleInputChange}
													className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
												/>
											</div>
											<div className="mt-2">
												<label htmlFor="vatPercentage" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
													VAT (%)
												</label>
												<input
													type="number"
													name="vatPercentage"
													id="vatPercentage"
													value={inventory.purchaseDetails?.vatPercentage || ''}
													onChange={handleInputChange}
													className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
												/>
											</div>
											<div className="mt-2">
												<label htmlFor="purchasePlace" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
													Purchase Place
												</label>
												<input
													type="text"
													name="purchasePlace"
													id="purchasePlace"
													value={inventory.purchaseDetails?.purchasePlace || ''}
													onChange={handleInputChange}
													className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
												/>
											</div>
											<div className="mt-2">
												<label htmlFor="purchaseDate" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
													Purchase Date
												</label>
												<input
													type="date"
													name="purchaseDate"
													id="purchaseDate"
													value={inventory.purchaseDetails?.purchaseDate || ''}
													onChange={handleInputChange}
													className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
												/>
											</div>
											<div className="mt-2">
												<label htmlFor="orderNumber" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
													Order Number
												</label>
												<input
													type="text"
													name="orderNumber"
													id="orderNumber"
													value={inventory.purchaseDetails?.orderNumber || ''}
													onChange={handleInputChange}
													className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
												/>
											</div>
										</div>
									)}

									{currentStep === 2 && (
										<div className="mt-4">
											<h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-white">Classification Info</h3>
											<div className="mt-2">
												<label htmlFor="category" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
													Category
												</label>
												<select
													name="category"
													id="category"
													value={inventory.category}
													onChange={handleInputChange}
													className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
												>
													{Object.keys(Categories).map((key) => (
														<option key={key} value={Categories[key as keyof typeof Categories]}>
															{key}
														</option>
													))}
												</select>
											</div>
											<div className="mt-2">
												<label htmlFor="source" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
													Source
												</label>
												<select
													name="source"
													id="source"
													value={inventory.source}
													onChange={handleInputChange}
													className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
												>
													{Object.keys(Sources).map((key) => (
														<option key={key} value={Sources[key as keyof typeof Sources]}>
															{key}
														</option>
													))}
												</select>
											</div>
											<div className="mt-2">
												<label htmlFor="collectionId" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
													Add to List / Create List
												</label>
												<input
													type="text"
													name="collectionId"
													id="collectionId"
													value={inventory.collectionId}
													onChange={handleInputChange}
													className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
												/>
											</div>
											<div className="mt-2 flex items-center">
												<input
													type="checkbox"
													name="autoList"
													id="autoList"
													checked={inventory.autoList}
													onChange={(e) => setInventory({ ...inventory, autoList: e.target.checked })}
													className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:text-white"
												/>
												<label htmlFor="autoList" className="ml-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
													Auto List
												</label>
											</div>
										</div>
									)}

									<div className="mt-4 flex justify-between">
										{currentStep > 0 && (
											<button
												type="button"
												onClick={handlePreviousStep}
												className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
											>
												Previous
											</button>
										)}
										{currentStep < steps.length - 1 ? (
											<button
												type="button"
												onClick={handleNextStep}
												className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
											>
												Next
											</button>
										) : (
											<button
												type="submit"
												disabled={loading}
												className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
											>
												{loading ? (
													<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
														<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
														<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
													</svg>
												) : (
													'Submit'
												)}
											</button>
										)}
									</div>
								</form>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default InventoryForm;
