import React, {useState} from "react";
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {TrashIcon, XMarkIcon} from '@heroicons/react/24/outline';
import {userStore} from "../state/User";
import {ClipboardIcon} from '@heroicons/react/24/outline';
import {Link, Route, Routes, useNavigate} from "react-router-dom";
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import {Dialog, Transition} from '@headlessui/react';
import userImage from "../images/settings-icon-account.png";
import invoiceImage from "../images/order-pending.png";

const tiers = [
	{
		name: 'Basic',
		id: 'tier-basic',
		href: '/signup?plan=Basic',
		priceMonthly: 'Free',
		description: 'Limited version with basic features.',
		mostPopular: false,
	},
	{
		name: 'Premium',
		id: 'tier-premium',
		href: '/signup?plan=Premium',
		priceMonthly: '$50',
		description: 'Full access to advanced features and integrations.',
		mostPopular: true,
	},
	{
		name: 'Enterprise',
		id: 'tier-enterprise',
		href: '/signup?plan=Enterprise',
		priceMonthly: 'Contact',
		description: 'Customized solutions with unlimited features.',
		mostPopular: false,
	},
];
const addons = [
	{
		name: 'Additional Platforms',
		id: 'addon-additional-platforms',
		price: '$5 per platform/month',
		description: 'Add more platforms to your subscription.',
	},
	{
		name: 'Team Support',
		id: 'addon-team-support',
		price: '$10/month per additional member',
		description: 'Add team support to your subscription.',
	},
	{
		name: 'Priority Support',
		id: 'addon-priority-support',
		price: '$20/month for 24/7 support',
		description: 'Get priority support with 24/7 availability.',
	},
];
const sections = [
	{
		name: 'Features',
		features: [
			{
				name: 'Cross Listing',
				tiers: {Basic: 'Up to 2 platforms', Premium: 'Up to 5 platforms', Enterprise: 'Unlimited platforms'}
			},
			{
				name: 'Supported Platforms',
				tiers: {Basic: '2 platforms', Premium: '5 platforms', Enterprise: 'Unlimited platforms'}
			},
			{name: 'Email/Inventory Tracking', tiers: {Basic: 'Limited', Premium: 'Full access', Enterprise: 'Full access'}},
			{
				name: 'In-App Messages',
				tiers: {Basic: 'Not available', Premium: 'Messages from platforms', Enterprise: 'Messages and responses'}
			},
			{name: 'Inventory Management Tools', tiers: {Basic: true, Premium: true, Enterprise: true}},
			{name: 'AI Insights', tiers: {Basic: false, Premium: true, Enterprise: true}},
			{name: 'Export Formats', tiers: {Basic: 'Basic (.txt, .csv)', Premium: 'All formats', Enterprise: 'All formats'}},
			{name: 'Scheduled and Auto Listing Support', tiers: {Basic: false, Premium: true, Enterprise: true}},
			{name: 'Team Support', tiers: {Basic: false, Premium: 'Add-on', Enterprise: true}},
		],
	},
	{
		name: 'Support',
		features: [
			{name: 'Customer Service', tiers: {Basic: 'Limited help', Premium: 'Timely help', Enterprise: 'Dedicated help'}},
			{name: 'Priority Support', tiers: {Basic: false, Premium: 'Add-on', Enterprise: 'Available (Add-on for 24/7)'}},
			{name: 'Custom Features', tiers: {Basic: false, Premium: false, Enterprise: 'Contact us for custom requests'}},
		],
	},
	{
		name: 'Add-Ons',
		features: [
			{
				name: 'Additional Platforms',
				tiers: {Basic: '$5 per platform/month', Premium: '$5 per platform/month', Enterprise: 'Included'}
			},
			{
				name: 'Team Support',
				tiers: {Basic: 'Not available', Premium: '$10/month per additional member', Enterprise: 'Included'}
			},
			{
				name: 'Priority Support',
				tiers: {Basic: 'Not available', Premium: '$20/month for 24/7 support', Enterprise: '$20/month for 24/7 support'}
			},
		],
	},
];
const secondaryNavigation = [
	{name: 'Account', href: '/profile'},
	{name: 'Billing', href: '/profile/billing'},
	{name: 'Plans & Addons', href: '/profile/plan'},
	{name: 'Teams', href: '/profile/teams'},
]
const teams: any[] = [];
const invoices: any[] = [
	// { id: '12345', amount: '$12.99', date: '01/01/2023', link: '#' },
	// { id: '12344', amount: '$12.99', date: '12/01/2022', link: '#' },
	// Add more invoices as needed
];
const stripePromise = loadStripe('pk_test_51PpluwRwi8cbrWgqI7zPnes08sLlXr01eukFqn9T9cshd2oADZe0XfWNNK5vG7Wdr9oFzmXGUHlshr43wKe3plPt00n1ThnBEx');
export const CheckoutForm = () => {
	const stripe = useStripe();
	const elements = useElements();
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading(true);
		if (!stripe || !elements) {
			return;
		}
		const cardElement = elements.getElement(CardElement);
		if (cardElement) {
			const {error, paymentMethod} = await stripe.createPaymentMethod({
				type: 'card',
				card: cardElement,
			});

			if (error) {
				console.error('[error]', error);
			} else {
				console.log('[PaymentMethod]', paymentMethod);
				// Handle the payment method as needed
			}
		}
		setLoading(false);
	};

	return (
		<form onSubmit={handleSubmit} className="bg-gray-100 dark:bg-gray-800 p-4 rounded-b-md shadow-sm">
			<h3 className="text-lg font-semibold text-gray-900 dark:text-white">Update Payment Method</h3>
			<div className="mt-4 border rounded p-4">
				<CardElement
					options={{
						style: {
							base: {
								color: 'gray',
								fontFamily: 'Arial, sans-serif',
								fontSmoothing: 'antialiased',
								fontSize: '16px',
								'::placeholder': {
									color: '#aab7c4',
								},
							},
							invalid: {
								color: '#fa755a',
								iconColor: '#fa755a',
							},
						},
					}}
				/>
			</div>
			<button
				type="submit"
				disabled={!stripe}
				className="mt-4 rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
			>
				{loading ? (
					<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
					     viewBox="0 0 24 24">
						<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
						        strokeWidth="4"></circle>
						<path className="opacity-75" fill="currentColor"
						      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
					</svg>
				) : (
					'Save Payment Method'
				)}
			</button>
		</form>
	);
};
export default function Profile() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState({
		personalInfo: false,
		changePassword: false,
		logoutOtherSessions: false,
		deleteAccount: false,
		billingInfo: false,
		addingAddon: false,
		planUpdate: false,
		paymentInformation: false,
		teamInvite: false
	});
	const [showPaymentForm, setShowPaymentForm] = useState(false);
	const [name, setName] = useState("Account");
	const [selectedAddons, setSelectedAddons] = useState<string[]>([]);
	const [inviteEmail, setInviteEmail] = useState('');
	const [teamMembers, setTeamMembers] = useState(teams);
	const [loadingStates, setLoadingStates] = useState<{ [key: string]: boolean }>({});
	const [loadingDelStates, setLoadingDelStates] = useState<{ [key: string]: boolean }>({});
	const [showDialog, setShowDialog] = useState(false);
	const [currentPlanId, setCurrentPlanId] = useState('tier-premium');
	const [selectedPlan, setSelectedPlan] = useState<any>(null);

	const handleRemoveUser = async (email: string) => {
		setLoadingDelStates((prev) => ({...prev, [email]: true}));
		try {
			// Simulate API call
			await new Promise((resolve) => setTimeout(resolve, 2000));
			setTeamMembers((prev) => prev.filter((member) => member.email !== email));
		} catch (error) {
			console.error('Error removing user:', error);
		} finally {
			setLoadingDelStates((prev) => ({...prev, [email]: false}));
		}
	};

	const handleResendInvite = async (email: string) => {
		setLoadingStates((prev) => ({...prev, [email]: true}));
		try {
			// Simulate API call
			await new Promise((resolve) => setTimeout(resolve, 2000));
			console.log(`Invite resent to ${email}`);
		} catch (error) {
			console.error('Error resending invite:', error);
		} finally {
			setLoadingStates((prev) => ({...prev, [email]: false}));
		}
	};

	const handleBillingInformationMethod = async (event: any) => {
		event.preventDefault();
		setLoading((prev) => ({...prev, billingInfo: true}));
		const formData = new FormData(event.currentTarget);
		const data = Object.fromEntries(formData.entries());
		console.log(data);
		// Call your API function here
		await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate API call
		setLoading((prev) => ({...prev, billingInfo: false}));
	};

	const handlePersonalInfoSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading((prev) => ({...prev, personalInfo: true}));
		const formData = new FormData(event.currentTarget);
		const data = Object.fromEntries(formData.entries());
		console.log(data);
		// Call your API function here
		await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate API call
		setLoading((prev) => ({...prev, personalInfo: false}));
	};

	const handleChangePasswordSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading((prev) => ({...prev, changePassword: true}));
		const formData = new FormData(event.currentTarget);
		const data = Object.fromEntries(formData.entries());
		console.log(data);
		// Call your API function here
		await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate API call
		setLoading((prev) => ({...prev, changePassword: false}));
	};

	const handleLogoutOtherSessionsSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading((prev) => ({...prev, logoutOtherSessions: true}));
		const formData = new FormData(event.currentTarget);
		const data = Object.fromEntries(formData.entries());
		console.log(data);
		// Call your API function here
		await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate API call
		setLoading((prev) => ({...prev, logoutOtherSessions: false}));
	};

	const handleDeleteAccountSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading((prev) => ({...prev, deleteAccount: true}));
		// Call your API function here
		await new Promise((resolve) => setTimeout(resolve, 2000)); // Simulate API call
		setLoading((prev) => ({...prev, deleteAccount: false}));
	};

	const handleChangePlan = async (planId: string) => {
		setLoading((prev) => ({...prev, planUpdate: true}));
		try {
			// Simulate API call
			await new Promise((resolve) => setTimeout(resolve, 2000));
			console.log(`Plan changed to ${planId}`);
		} catch (error) {
			console.error('Error changing plan:', error);
		} finally {
			setLoading((prev) => ({...prev, planUpdate: false}));
		}
	};

	const handleInviteSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoading((prev) => ({...prev, teamInvite: true}));
		try {
			// Simulate API call to invite a team member
			await new Promise((resolve) => setTimeout(resolve, 2000));
			setTeamMembers([...teamMembers, {
				email: inviteEmail,
				name: 'New Member',
				dateJoined: new Date().toISOString().split('T')[0],
				lastLogin: '',
				inviteState: 'pending',
			}]);
			setInviteEmail('');
		} catch (error) {
			console.error('Error inviting team member:', error);
		} finally {
			setLoading((prev) => ({...prev, teamInvite: false}));
		}
	};

	const handleToggleAddon = (addonId: string) => {
		if (addonId === "addon-additional-platforms") {
			return navigate("/marketplaces");
		}
		setSelectedAddons((prevSelectedAddons) =>
			prevSelectedAddons.includes(addonId)
				? prevSelectedAddons.filter((id) => id !== addonId)
				: [...prevSelectedAddons, addonId]
		);
	};

	const handleSaveAddons = async () => {
		setLoading((prev) => ({...prev, addingAddon: true}));
		try {
			// Simulate API call
			await new Promise((resolve) => setTimeout(resolve, 2000));
			console.log('Add-ons saved:', selectedAddons);
		} catch (error) {
			console.error('Error saving add-ons:', error);
		} finally {
			setLoading((prev) => ({...prev, addingAddon: false}));
		}
	};

	const handlePlanButtonClick = (plan: any) => {
		setSelectedPlan(plan);
		setShowDialog(true);
	};

	const handleCloseDialog = () => {
		setShowDialog(false);
	};

	const getPlanButtonLabel = (planId: string) => {
		if (planId === currentPlanId) return 'View Plan';
		const currentPlanIndex = tiers.findIndex((tier) => tier.id === currentPlanId);
		const planIndex = tiers.findIndex((tier) => tier.id === planId);
		return planIndex > currentPlanIndex ? 'Upgrade Plan' : 'Downgrade Plan';
	};

	const copyToClipboard = (text: string) => {
		navigator.clipboard.writeText(text).then(
			() => {
				console.log('Text copied to clipboard');
			},
			(err) => {
				console.error('Failed to copy text: ', err);
			}
		);
	};

	const accountComponent = <div className="divide-y divide-gray-200 dark:divide-white/5">
		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Personal
					Information</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					Use a permanent address where you can receive mail.
				</p>
			</div>

			<form className="md:col-span-2" onSubmit={handlePersonalInfoSubmit}>
				<div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
					<div className="sm:col-span-3">
						<label htmlFor="firstName"
						       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							First name
						</label>
						<div className="mt-2">
							<input
								id="firstName"
								name="firstName"
								type="text"
								autoComplete="first-name"
								className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="sm:col-span-3">
						<label htmlFor="lastName"
						       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							Last name
						</label>
						<div className="mt-2">
							<input
								id="lastName"
								name="lastName"
								type="text"
								autoComplete="last-name"
								className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="col-span-full">
						<label htmlFor="email"
						       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							Email address
						</label>
						<div className="mt-2">
							<input
								id="email"
								name="email"
								type="email"
								autoComplete="email"
								className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="col-span-full">
						<label htmlFor="username"
						       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							Username
						</label>
						<div className="mt-2">
							<div
								className="flex rounded-md bg-gray-100 dark:bg-white/5 ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
								<input
									id="username"
									name="username"
									type="text"
									placeholder="janesmith"
									autoComplete="username"
									className="flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 dark:text-white focus:ring-0 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>
					</div>

					<div className="col-span-full">
						<label htmlFor="discordChannelUrl"
						       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							Discord Channel Webhook
						</label>
						<div className="mt-2">
							<div
								className="flex rounded-md bg-gray-100 dark:bg-white/5 ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
								<input
									id="discordChannelUrl"
									name="discordChannelUrl"
									type="text"
									placeholder="Discord Channel Webhook"
									autoComplete="discordChannelUrl"
									className="flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 dark:text-white focus:ring-0 sm:text-sm sm:leading-6"
								/>
							</div>
						</div>
					</div>
				</div>

				<div className="mt-8 flex">
					<button
						type="submit"
						className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
						disabled={loading.personalInfo}
					>
						{loading.personalInfo ? (
							<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
							     viewBox="0 0 24 24">
								<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
								        strokeWidth="4"></circle>
								<path className="opacity-75" fill="currentColor"
								      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
							</svg>
						) : (
							'Save'
						)}
					</button>
				</div>
			</form>
		</div>
		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Change password</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					Update your password associated with your account.
				</p>
			</div>

			<form className="md:col-span-2" onSubmit={handleChangePasswordSubmit}>
				<div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
					<div className="col-span-full">
						<label htmlFor="current-password"
						       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							Current password
						</label>
						<div className="mt-2">
							<input
								id="current-password"
								name="current_password"
								type="password"
								autoComplete="current-password"
								className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="col-span-full">
						<label htmlFor="new-password"
						       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							New password
						</label>
						<div className="mt-2">
							<input
								id="new-password"
								name="new_password"
								type="password"
								autoComplete="new-password"
								className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="col-span-full">
						<label htmlFor="confirm-password"
						       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							Confirm password
						</label>
						<div className="mt-2">
							<input
								id="confirm-password"
								name="confirm_password"
								type="password"
								autoComplete="new-password"
								className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>
				</div>

				<div className="mt-8 flex">
					<button
						type="submit"
						className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
						disabled={loading.changePassword}
					>
						{loading.changePassword ? (
							<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
							     viewBox="0 0 24 24">
								<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
								        strokeWidth="4"></circle>
								<path className="opacity-75" fill="currentColor"
								      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
							</svg>
						) : (
							'Save'
						)}
					</button>
				</div>
			</form>
		</div>
		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">User ID</h2>
			</div>
			<div>
				<p
					className="mt-1 text-sm leading-6 flex space-x-2 font-semibold uppercase text-gray-600 dark:text-gray-400">
					{/*@ts-ignore*/}
					<span>{userStore.user!.id || userStore.user!.user_id}</span>
					<span onClick={() => {
						// @ts-ignore
						copyToClipboard(userStore.user!.id || userStore.user!.user_id)
					}}> <ClipboardIcon className={`h-6 w-6 cursor-pointer`}/> </span>
				</p>
			</div>
		</div>
		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Connect 3rd party
					Accounts</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					Connect your marketplace accounts for email tracking and inventory listing.
				</p>
			</div>

			<div className="md:col-span-2">
				<div className="grid grid-cols-4 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
					<div className="col-span-1">
						<a href="https://api.tracknlist.com/api/google/login" className="block w-full">
							<svg viewBox="0 0 24 24" aria-hidden="true" className="h-6 w-6 hover:opacity-80">
								<path
									d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
									fill="#EA4335"></path>
								<path
									d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
									fill="#4285F4"></path>
								<path
									d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
									fill="#FBBC05"></path>
								<path
									d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
									fill="#34A853"></path>
							</svg>
						</a>
					</div>
					<div className="col-span-1">
						<a href="https://api.tracknlist.com/api/ebay/login" className="block w-full">
							<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 hover:opacity-80" viewBox="0 0 24 24"
							     id="ebay">
								<path fill="#E53238"
								      d="M3.103 9.29C1.411 9.29 0 10.008 0 12.175c0 1.717.949 2.797 3.147 2.797 2.588 0 2.754-1.705 2.754-1.705H4.647s-.269.918-1.576.918c-1.065 0-1.831-.719-1.831-1.728h4.792v-.633c0-.996-.633-2.534-2.929-2.534zm-.043.809c1.014 0 1.705.621 1.705 1.552H1.268c0-.989.902-1.552 1.792-1.552z"></path>
								<path fill="#0064D2"
								      d="M6.031 7.192v6.692c0 .38-.027.913-.027.913H7.2s.043-.383.043-.733c0 0 .591.924 2.198.924 1.692 0 2.841-1.175 2.841-2.858 0-1.566-1.056-2.825-2.838-2.825-1.669 0-2.188.901-2.188.901V7.192H6.031zm3.103 2.942c1.149 0 1.88.853 1.88 1.997 0 1.227-.844 2.03-1.871 2.03-1.226 0-1.888-.958-1.888-2.019 0-.989.593-2.008 1.879-2.008z"></path>
								<path fill="#F5AF02"
								      d="M15.254 9.29c-2.547 0-2.71 1.394-2.71 1.617h1.268s.066-.814 1.355-.814c.837 0 1.486.383 1.486 1.12v.262h-1.486c-1.973 0-3.016.577-3.016 1.748 0 1.153.964 1.78 2.266 1.78 1.775 0 2.347-.981 2.347-.981 0 .39.03.775.03.775h1.127s-.044-.476-.044-.781v-2.635c-.001-1.728-1.394-2.091-2.623-2.091zm1.398 2.972v.35c0 .456-.281 1.59-1.938 1.59-.907 0-1.296-.453-1.296-.978 0-.956 1.31-.962 3.234-.962z"></path>
								<path fill="#86B817"
								      d="M17.194 9.509h1.426l2.047 4.1 2.042-4.1H24l-3.719 7.3h-1.355l1.073-2.035-2.805-5.265z"></path>
							</svg>
						</a>
					</div>
					<div className="col-span-1">
						<a href="https://api.tracknlist.com/api/amazon/login" className="block w-full">
							<svg xmlns="http://www.w3.org/2000/svg" className={"h-6 w-6 hover:opacity-80"}
							     viewBox="0 0 48 48" id="amazon">
								<g fill="none" fill-rule="evenodd">
									<g transform="translate(-601 -560)">
										<g transform="translate(601 560)">
											<path fill="#343B45"
											      d="M25.403 25.96c-.743 1.482-2.015 2.436-3.393 2.758-.208 0-.527.105-.846.105-2.329 0-3.706-1.802-3.706-4.45 0-3.394 2.012-4.981 4.552-5.726 1.378-.317 2.97-.424 4.558-.424v1.273c0 2.437.105 4.343-1.165 6.464zm1.165-12.608c-1.377.105-2.969.21-4.558.418-2.435.322-4.87.746-6.88 1.7-3.92 1.59-6.57 4.98-6.57 9.959 0 6.257 4.024 9.433 9.113 9.433 1.693 0 3.07-.214 4.337-.528 2.018-.638 3.709-1.804 5.721-3.925 1.166 1.59 1.487 2.335 3.497 4.03.53.209 1.06.209 1.481-.105 1.273-1.062 3.5-2.97 4.663-4.03.53-.423.426-1.06.104-1.586-1.163-1.485-2.331-2.758-2.331-5.619v-9.538c0-4.026.322-7.736-2.645-10.489C30.065.85 26.25 0 23.283 0H22.01C16.612.313 10.894 2.646 9.618 9.323c-.212.85.426 1.166.85 1.27l5.932.743c.635-.107.954-.638 1.058-1.163.528-2.332 2.436-3.498 4.552-3.713h.427c1.272 0 2.65.531 3.389 1.593.847 1.27.742 2.967.742 4.452v.847z"></path>
											<path fill="#FF9A00"
											      d="M47.994 35.946v-.002c-.022-.5-.127-.881-.335-1.198l-.023-.03-.025-.032c-.212-.231-.415-.319-.635-.415-.658-.254-1.615-.39-2.766-.392-.827 0-1.739.079-2.656.28l-.003-.063-.923.308-.017.008-.522.17v.022a8.17 8.17 0 0 0-1.684.946c-.322.24-.587.56-.602 1.048a.978.978 0 0 0 .35.75 1.119 1.119 0 0 0 .861.232l.045-.002.034-.006c.452-.096 1.11-.161 1.88-.268.66-.074 1.36-.127 1.967-.127.429-.003.815.028 1.08.084a1.208 1.208 0 0 1 .328.11.955.955 0 0 1 .025.266c.006.508-.208 1.451-.505 2.372-.288.92-.638 1.843-.869 2.456a1.246 1.246 0 0 0-.093.466c-.006.246.096.545.31.743.21.197.48.276.706.276h.011c.339-.003.627-.138.875-.333 2.343-2.106 3.158-5.472 3.192-7.367l-.006-.302zm-6.945 2.92a1.645 1.645 0 0 0-.714.16c-.257.102-.52.221-.768.326l-.364.152-.474.19v.005c-5.15 2.09-10.56 3.315-15.567 3.422-.184.006-.37.006-.548.006-7.874.005-14.297-3.648-20.777-7.248a1.482 1.482 0 0 0-.685-.181c-.291 0-.59.11-.808.313a1.108 1.108 0 0 0-.344.805c-.003.392.209.754.505.988C6.587 43.087 13.253 47.994 22.22 48c.175 0 .353-.006.53-.008 5.704-.128 12.153-2.056 17.16-5.201l.03-.02a17.54 17.54 0 0 0 1.928-1.333c.384-.285.65-.731.65-1.194-.017-.822-.715-1.378-1.468-1.378z"></path>
										</g>
									</g>
								</g>
							</svg>
						</a>
					</div>
					<div className="col-span-1">
						<a href="https://api.tracknlist.com/api/shopify/login" className="block w-full">
							<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 hover:opacity-80" fill="none"
							     viewBox="0 0 48 48"
							     id="shopify">
								<path fill="#95BF47" fill-rule="evenodd"
								      d="M39.5162 8.96641C39.6859 8.9947 39.9122 9.10784 39.9405 9.3624C39.9574 9.51497 41.8335 22.2041 43.3343 32.3545C44.3377 39.1409 45.1732 44.7924 45.1732 44.8038L30.3518 48L3 42.8804C3 42.8804 6.50737 15.7266 6.64879 14.7366C6.8185 13.4638 6.87507 13.4355 8.20448 13.0112C8.3271 12.9805 8.88159 12.8087 9.74646 12.5407C10.4776 12.3141 11.4307 12.0187 12.5321 11.6818C13.4655 7.75015 16.4072 0.0282852 22.6017 0C23.3936 0 24.327 0.452563 25.0907 1.44255H25.317C27.9758 1.44255 29.5032 3.70536 30.3235 6.16618C31.2004 5.88332 31.7378 5.71361 31.7661 5.71361C31.9923 5.65704 32.5298 5.57219 32.8126 5.85504C33.0672 6.13789 35.6411 8.68356 35.6411 8.68356C35.6411 8.68356 39.3465 8.93813 39.5162 8.96641ZM27.1432 7.1642C27.7508 6.98404 28.319 6.81556 28.8244 6.64707C28.2587 4.86511 27.3253 3.30942 25.9393 3.11142C26.2787 4.07312 26.505 5.31767 26.505 6.84507V7.3542C26.7218 7.28918 26.9348 7.226 27.1432 7.1642ZM24.2705 3.42242C24.6382 4.38412 24.8928 5.74181 24.8928 7.58035V7.83491C23.3937 8.28746 21.7533 8.79656 20.1128 9.30567L20.1126 9.30574C21.046 5.79838 22.7714 4.07298 24.2705 3.42242ZM23.2239 1.95163C22.9694 1.78192 22.6865 1.69707 22.4319 1.69707C17.9912 1.69707 15.5021 7.52382 14.4272 11.0878C15.6152 10.72 16.9163 10.3241 18.2174 9.92806C19.1226 5.23272 21.244 2.88504 23.2239 1.95163Z"
								      clip-rule="evenodd"></path>
								<path fill="#5E8E3E"
								      d="M39.5162 8.96639C39.3465 8.9381 35.6411 8.68354 35.6411 8.68354C35.6411 8.68354 33.0672 6.13787 32.8126 5.85501C32.6995 5.74187 32.558 5.6853 32.4166 5.6853L30.3518 48L45.1732 44.8037C45.1732 44.8037 39.9688 9.61695 39.9405 9.39067C39.9122 9.1361 39.6859 8.99467 39.5162 8.96639Z"></path>
								<path fill="#fff"
								      d="M25.317 17.1691L23.4785 22.5999C23.4785 22.5999 21.8662 21.7513 19.9145 21.7513C17.0295 21.7513 16.888 23.5616 16.888 24.0142C16.888 26.5033 23.3653 27.4367 23.3653 33.2634C23.3653 37.8456 20.452 40.7873 16.5486 40.7873C11.8533 40.7873 9.44902 37.8739 9.44902 37.8739L10.6936 33.716C10.6936 33.716 13.1544 35.8374 15.2475 35.8374C16.6052 35.8374 17.1709 34.7625 17.1709 33.9705C17.1709 30.7177 11.8533 30.5763 11.8533 25.2587C11.8533 20.7896 15.0778 16.4337 21.5551 16.4337C24.1008 16.462 25.317 17.1691 25.317 17.1691Z"></path>
							</svg>
						</a>
					</div>
				</div>

				<div className="mt-4 block">
					<a
						href="/settings/accounts"
						className="text-sm font-semibold text-deepPurple hover:text-brightPurple"
					>
						Configure More Accounts
					</a>
				</div>
			</div>
		</div>
		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Log out other
					sessions</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					Please enter your password to confirm you would like to log out of your other sessions across all of
					your devices.
				</p>
			</div>

			<form className="md:col-span-2" onSubmit={handleLogoutOtherSessionsSubmit}>
				<div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
					<div className="col-span-full">
						<label htmlFor="logout-password"
						       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							Your password
						</label>
						<div className="mt-2">
							<input
								id="logout-password"
								name="password"
								type="password"
								autoComplete="current-password"
								className="block w-full pl-2 rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>
				</div>

				<div className="mt-8 flex">
					<button
						type="submit"
						className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
						disabled={loading.logoutOtherSessions}
					>
						{loading.logoutOtherSessions ? (
							<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
							     viewBox="0 0 24 24">
								<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
								        strokeWidth="4"></circle>
								<path className="opacity-75" fill="currentColor"
								      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
							</svg>
						) : (
							'Log out other sessions'
						)}
					</button>
				</div>
			</form>
		</div>
		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Delete account</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					No longer want to use our service? You can delete your account here. This action is not reversible.
					All information related to this account will be deleted permanently.
				</p>
			</div>

			<form className="flex items-start md:col-span-2" onSubmit={handleDeleteAccountSubmit}>
				<button
					type="submit"
					className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400"
					disabled={loading.deleteAccount}
				>
					{loading.deleteAccount ? (
						<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
						     viewBox="0 0 24 24">
							<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
							        strokeWidth="4"></circle>
							<path className="opacity-75" fill="currentColor"
							      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
						</svg>
					) : (
						'Yes, delete my account'
					)}
				</button>
			</form>
		</div>
	</div>
	const billingComponent = <div className="divide-y divide-gray-200 dark:divide-white/5">
		{/* Payment Method Update */}
		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Payment Method</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					Update your payment method.
				</p>
			</div>

			<div className="md:col-span-2">
				<div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-t-md shadow-sm">
					<h3 className="text-lg font-semibold text-gray-900 dark:text-white">Payment Information</h3>
					{/*<p className="mt-2 text-sm text-gray-600 dark:text-gray-400">Expires 12/23</p>*/}
					<button
						onClick={() => {
							setShowPaymentForm(!showPaymentForm)
						}}
						className="mt-4 rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
					>
						{userStore.user?.paymentMethod ? "Update" : "Add"} Payment Method
					</button>
				</div>
				{showPaymentForm && (<Elements stripe={stripePromise}> <CheckoutForm/> </Elements>)}
			</div>
		</div>

		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Update Billing Information</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					Update your billing address and other information.
				</p>
			</div>

			<form className="md:col-span-2" onSubmit={handleBillingInformationMethod}>
				<div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
					<div className="sm:col-span-3">
						<label htmlFor="billingFirstName"
						       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							First name
						</label>
						<div className="mt-2">
							<input
								id="billingFirstName"
								name="billingFirstName"
								type="text"
								autoComplete="billing-first-name"
								className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="sm:col-span-3">
						<label htmlFor="billingLastName"
						       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							Last name
						</label>
						<div className="mt-2">
							<input
								id="billingLastName"
								name="billingLastName"
								type="text"
								autoComplete="billing-last-name"
								className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="col-span-full">
						<label htmlFor="billingAddress"
						       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							Address
						</label>
						<div className="mt-2">
							<input
								id="billingAddress"
								name="billingAddress"
								type="text"
								autoComplete="street-address"
								className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="sm:col-span-3">
						<label htmlFor="billingCity" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							City
						</label>
						<div className="mt-2">
							<input
								id="billingCity"
								name="billingCity"
								type="text"
								autoComplete="address-level2"
								className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="sm:col-span-3">
						<label htmlFor="billingState" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							State
						</label>
						<div className="mt-2">
							<input
								id="billingState"
								name="billingState"
								type="text"
								autoComplete="address-level3"
								className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="sm:col-span-3">
						<label htmlFor="billingZip"
						       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							Postal code
						</label>
						<div className="mt-2">
							<input
								id="billingZip"
								name="billingZip"
								type="text"
								autoComplete="billing-zip"
								className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
							/>
						</div>
					</div>

					<div className="sm:col-span-3">
						<label htmlFor="billingCountry"
						       className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							Country
						</label>
						<div className="mt-2">
							<select
								id="billingCountry"
								name="billingCountry"
								autoComplete="country"
								className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
							>
								<option disabled={true}>Select Country</option>
								<option value={"United States Of America"}>United States</option>
								<option value={"Canada"}>Canada</option>
								<option value={"Mexico"}>Mexico</option>
								{/* Add more countries as needed */}
							</select>
						</div>
					</div>
				</div>
				<div className="mt-8 flex">
					<button
						type="submit"
						className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
					>
						{loading.billingInfo ? (
							<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
							     viewBox="0 0 24 24">
								<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
								        strokeWidth="4"></circle>
								<path className="opacity-75" fill="currentColor"
								      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
							</svg>
						) : (
							'Save'
						)}
					</button>
				</div>
			</form>

		</div>

		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Billing History</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					View your past invoices.
				</p>
			</div>

			<div className="md:col-span-2">
				{invoices.length > 0 ? (
					<div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-md shadow-sm">
						<ul className="divide-y divide-gray-200 dark:divide-gray-700">
							{invoices.map((invoice) => (
								<li key={invoice.id} className="py-4 flex justify-between items-center">
									<span className="text-sm text-gray-600 dark:text-gray-400">Invoice #{invoice.id}</span>
									<span className="text-sm text-gray-600 dark:text-gray-400">{invoice.amount}</span>
									<span className="text-sm text-gray-600 dark:text-gray-400">{invoice.date}</span>
									<a href={invoice.link} className="text-sm text-indigo-500 hover:underline">Download</a>
								</li>
							))}
						</ul>
					</div>
				) : (
					<div className="flex flex-col items-center justify-center">
						<img src={invoiceImage} alt="No Team Members" className="mb-8 w-80 h-80"/>
						<h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100">No Invoice Generated yet!</h1>
						<p className="mt-4 text-lg text-gray-700 dark:text-gray-300">
							Kindly wait till subscription period has elapsed.
						</p>
					</div>
				)}
			</div>
		</div>
	</div>
	const plansAndAddonsComponent = <div className="divide-y divide-gray-200 dark:divide-white/5">
		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Plans</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					Choose a plan that fits your needs and add additional features as required.
				</p>
			</div>
			<div className="md:col-span-2">
				<div className="space-y-6">
					{tiers.map((tier) => (
						<div key={tier.id} className="bg-gray-100 dark:bg-gray-800 p-4 rounded-md shadow-sm">
							<h3 className="text-lg font-semibold text-gray-900 dark:text-white">{tier.name}</h3>
							<p className="mt-2 text-sm text-gray-600 dark:text-gray-400">{tier.description}</p>
							<p className="mt-2 text-sm text-gray-600 dark:text-gray-400">{tier.priceMonthly}</p>
							<button
								onClick={() => handlePlanButtonClick(tier)}
								className="mt-4 rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
							>
								{getPlanButtonLabel(tier.id)}
							</button>
						</div>
					))}
				</div>
			</div>
		</div>
		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Add-Ons</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					Select additional features to enhance your subscription.
				</p>
			</div>

			<div className="md:col-span-2">
				<div className="space-y-6">
					{addons.map((addon) => (
						<div key={addon.id} className="bg-gray-100 dark:bg-gray-800 p-4 rounded-md shadow-sm">
							<h3 className="text-lg font-semibold text-gray-900 dark:text-white">{addon.name}</h3>
							<p className="mt-2 text-sm text-gray-600 dark:text-gray-400">{addon.description}</p>
							<p className="mt-2 text-sm text-gray-600 dark:text-gray-400">{addon.price}</p>
							<button
								onClick={() => handleToggleAddon(addon.id)}
								className={`mt-4 rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${
									selectedAddons.includes(addon.id)
										? 'bg-red-500 hover:bg-red-400'
										: 'bg-indigo-500 hover:bg-indigo-400'
								} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500`}
							>
								{selectedAddons.includes(addon.id) ? 'Remove Add-On' : 'Add Add-On'}
							</button>
						</div>
					))}
				</div>

				<div className="mt-8 flex justify-center">
					<button
						onClick={handleSaveAddons}
						className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
					>
						Save Add-Ons
					</button>
				</div>
			</div>
		</div>
		<Transition appear show={showDialog} as={React.Fragment}>
			<Dialog as="div" className="relative z-10" onClose={handleCloseDialog}>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25"/>
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={React.Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel
								className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
								<div className="flex justify-between items-center">
									<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
										{selectedPlan?.name} Plan
									</Dialog.Title>
									<button
										type="button"
										className="inline-flex justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-sm font-medium text-white hover:bg-red-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
										onClick={handleCloseDialog}
									>
										<XMarkIcon className="h-6 w-6"/>
									</button>
								</div>
								<div className="mt-2">
									<p className="text-sm text-gray-500 dark:text-gray-400">{selectedPlan?.description}</p>
									<div className="mt-4">
										{sections.map((section) => (
											<div key={section.name} className="mb-4">
												<h4 className="text-md font-semibold text-gray-900 dark:text-white">{section.name}</h4>
												<ul className="mt-2 space-y-1">
													{section.features.map((feature) => (
														<li key={feature.name} className="text-sm text-gray-600 dark:text-gray-400">
															<span className="font-medium text-gray-900 dark:text-white">{feature.name}: </span>
															{/*@ts-ignore*/}
															{(feature.tiers[selectedPlan?.name] && feature.tiers[selectedPlan?.name].toString()) || "Addon"}
														</li>
													))}
												</ul>
											</div>
										))}
									</div>
								</div>

								<div className="mt-4">
									<button
										type="button"
										className="inline-flex justify-center rounded-md border border-transparent bg-indigo-500 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
										onClick={selectedPlan?.id === currentPlanId ? handleCloseDialog : (e: any) => {
											e.preventDefault();
											setCurrentPlanId(selectedPlan?.id);
											handleCloseDialog();
										}}
									>
										{selectedPlan?.id === currentPlanId ? 'Close' : 'Choose Plan'}
									</button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	</div>
	const teamsComponent = <div className="divide-y divide-gray-200 dark:divide-white/5">
		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Invite Team Members</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					Invite new team members by entering their email address.
				</p>
			</div>

			<form className="md:col-span-2" onSubmit={handleInviteSubmit}>
				<div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
					<div className="col-span-full">
						<label htmlFor="invite-email" className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
							Email address
						</label>
						<div className="mt-2">
							<input
								id="invite-email"
								name="invite-email"
								type="email"
								value={inviteEmail}
								onChange={(e) => setInviteEmail(e.target.value)}
								autoComplete="email"
								className="block w-full rounded-md border-0 bg-gray-100 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
								required
							/>
						</div>
					</div>
				</div>

				<div className="mt-8 flex">
					<button
						type="submit"
						className="rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
						disabled={loading.teamInvite}
					>
						{loading.teamInvite ? (
							<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
							     viewBox="0 0 24 24">
								<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
								<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
							</svg>
						) : (
							'Send Invite'
						)}
					</button>
				</div>
			</form>
		</div>

		<div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
			<div>
				<h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">Team Members</h2>
				<p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
					View and manage your team members.
				</p>
			</div>

			<div className="md:col-span-2">
				{teamMembers.length > 0 ? (
					<div className="overflow-x-auto">
						<table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
							<thead className="bg-gray-50 dark:bg-gray-800">
							<tr>
								<th scope="col"
								    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
									Email
								</th>
								<th scope="col"
								    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
									Name
								</th>
								<th scope="col"
								    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
									Date Joined
								</th>
								<th scope="col"
								    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
									Last Login
								</th>
								<th scope="col"
								    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">
									Actions
								</th>
							</tr>
							</thead>
							<tbody className="bg-white dark:bg-gray-900 divide-y divide-gray-200 dark:divide-gray-700">
							{teamMembers.map((member, index) => (
								<tr key={index}>
									<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">{member.email}</td>
									<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">{member.name}</td>
									<td
										className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">{member.dateJoined}</td>
									<td
										className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-white">{member.lastLogin}</td>
									<td className="px-6 py-4 flex whitespace-nowrap text-sm text-gray-900 dark:text-white">
										{member.inviteState === 'pending' && (
											<button
												onClick={() => handleResendInvite(member.email)}
												className="mr-2 rounded-md bg-yellow-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-yellow-500"
												disabled={loadingStates[member.email]}
											>
												{loadingStates[member.email] ? (
													<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
													     fill="none"
													     viewBox="0 0 24 24">
														<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
														        strokeWidth="4"></circle>
														<path className="opacity-75" fill="currentColor"
														      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
													</svg>
												) : (
													'Resend Invite'
												)}
											</button>
										)}
										{member.inviteState === 'rejected' && (
											<button
												onClick={() => handleResendInvite(member.email)}
												className="mr-2 rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500"
												disabled={loadingStates[member.email]}
											>
												{loadingStates[member.email] ? (
													<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg"
													     fill="none"
													     viewBox="0 0 24 24">
														<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
														        strokeWidth="4"></circle>
														<path className="opacity-75" fill="currentColor"
														      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
													</svg>
												) : (
													'Resend Invite'
												)}
											</button>
										)}
										<button
											onClick={() => handleRemoveUser(member.email)}
											className="rounded-md bg-gray-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
											disabled={loadingDelStates[member.email]}
										>
											{loadingDelStates[member.email] ? (
												<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
												     viewBox="0 0 24 24">
													<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
													        strokeWidth="4"></circle>
													<path className="opacity-75" fill="currentColor"
													      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
												</svg>
											) : (
												<TrashIcon className="h-5 w-5"/>
											)}
										</button>
									</td>
								</tr>
							))}
							</tbody>
						</table>
					</div>
				) : (
					<div className="flex flex-col items-center justify-center">
						<img src={userImage} alt="No Team Members" className="mb-8 w-80 h-80"/>
						<h1 className="text-3xl font-bold text-gray-900 dark:text-gray-100">No Team Members</h1>
						<p className="mt-4 text-lg text-gray-700 dark:text-gray-300">
							You have not added any team members yet.
						</p>
					</div>
				)}
			</div>
		</div>
	</div>
	return (
		<main>
			<h1 className="sr-only">Account Settings</h1>
			<header className="border-b border-gray-300 dark:border-gray-700 border-white/5">
				{/* Secondary navigation */}
				<nav className="flex overflow-x-auto py-4">
					<ul
						role="list"
						className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-400 sm:px-6 lg:px-8"
					>
						{secondaryNavigation.map((item) => (
							<li key={item.name}>
								<Link to={item.href} onClick={() => {
									setName(item.name)
								}} className={name === item.name ? 'text-indigo-400' : ''}>
									{item.name}
								</Link>
							</li>
						))}
					</ul>
				</nav>
			</header>
			<Routes>
				<Route path="/" element={accountComponent}/>
				<Route path="/billing" element={billingComponent}/>
				<Route path="/plan" element={plansAndAddonsComponent}/>
				<Route path="/teams" element={teamsComponent}/>
			</Routes>
		</main>
	);
}
