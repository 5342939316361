import React, {useEffect, useState} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import Homepage from './pages/Homepage';
import NotFound from './pages/NotFound';
import {Dialog, Menu, Transition} from '@headlessui/react';
import {
	Bars3Icon,
	BellIcon,
	ChartPieIcon,
	BanknotesIcon,
	Cog6ToothIcon,
	RectangleGroupIcon,
	ArchiveBoxIcon,
	HomeIcon,
	XMarkIcon,
	PlusCircleIcon,
	EnvelopeIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	BuildingStorefrontIcon,
	ChatBubbleLeftRightIcon,
	ArrowLongRightIcon
} from '@heroicons/react/24/outline';
import {ChevronDownIcon, MagnifyingGlassIcon} from '@heroicons/react/20/solid';
import {Link} from 'react-router-dom';
import logo from "./images/logo.png";
import Inventory from './pages/Inventory';
import Categories from './pages/Categories';
import Marketplaces from './pages/Marketplaces';
import Sales from './pages/Sales';
import Reports from './pages/Reports';
import Settings from './pages/Settings';
import Profile from './pages/Profile';
import UnderConstruction from "./pages/UnderConstruction";
import {userStore} from "./state/User";
import Messages from "./pages/Messages";
import Chats from "./pages/Chats";
import Notifications from "./pages/Notifications";
import {notificationsStore} from "./state/Notification";
import InventoryForm from "./components/CreateInventory";
import ListingForm from "./components/CreateListing";
import CollectionForm from "./components/CreateCollection";

const navigation = [
	{name: 'Home', href: '/', icon: HomeIcon, current: true},
	{name: 'Inventory', href: '/inventory', icon: ArchiveBoxIcon, current: false},
	{name: 'Categories', href: '/categories', icon: RectangleGroupIcon, current: false},
	{name: 'Marketplaces & Listings', href: '/marketplaces', icon: BuildingStorefrontIcon, current: false},
	{name: 'Sales', href: '/sales', icon: BanknotesIcon, current: false},
	{name: 'Reports', href: '/reports', icon: ChartPieIcon, current: false},
];

const userNavigation = [
	{name: 'Your profile', href: '/profile'},
	{name: 'Sign out', href: '#'},
];

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ');
}

// is th page under construction or nuh?
const isUnderConstruction = false;


const App: React.FC = () => {
	const location = useLocation();
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [sidebarExpanded, setSidebarExpanded] = useState(true);
	const [notifications, setNotifications] = useState(false);
	const [chats, setChats] = useState(false);
	const [messages, setMessages] = useState(false);
	const [createInventory, setCreateInventory] = useState(false);
	const [createListing, setListing] = useState(false);
	const [createCollection, setCollection] = useState(false);
	const [current, setCurrent] = useState('/');

	// Update current state whenever the route changes
	useEffect(() => {
		setCurrent(location.pathname);
		const user = JSON.parse(window.localStorage.getItem("user") || "null");
		if (user) { userStore.setUser(user); }
	}, [location.pathname]);

	const handleSearch = (event: any) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			const query = event.target.value;
			console.log('Search query:', query);
			// Perform search action here
		}
	};

	const handleCreate = (inv:boolean, col:boolean, list:boolean) => {
		setCreateInventory(inv);
		setCollection(col);
		setListing(list);
	}

	const handleAddProductClick = () => {
		handleCreate(true, false, false);
	};

	let pageElements = <>
		<Routes>
			<Route path="/" element={<Homepage/>}/>
			<Route path="/inventory/*" element={<Inventory handleCreateInventory={() => {handleCreate(true, false, false)}} handleCreateCollection={() => {handleCreate(false, true, false)}} handleCreateListing={() => {handleCreate(false, false, true)}}/>}/>
			<Route path="/categories/*" element={<Categories handleCreateInventory={() => {handleCreate(true, false, false)}} handleCreateCollection={() => {handleCreate(false, true, false)}} handleCreateListing={() => {handleCreate(false, false, true)}} />}/>
			<Route path="/marketplaces/*" element={<Marketplaces handleCreateInventory={() => {handleCreate(true, false, false)}} handleCreateCollection={() => {handleCreate(false, true, false)}} handleCreateListing={() => {handleCreate(false, false, true)}}/>}/>
			<Route path="/sales/*" element={<Sales/>}/>
			<Route path="/reports" element={<Reports/>}/>
			<Route path="/settings/*" element={<Settings/>}/>
			<Route path="/profile/*" element={<Profile/>}/>
			<Route path="/notifications" element={<Notifications />}/>
			<Route path="/chats" element={<Chats />}/>
			<Route path="/messages" element={<Messages/>}/>
			<Route path="*" element={<NotFound/>}/>
		</Routes>
	</>

	if (isUnderConstruction) {
		pageElements = <UnderConstruction/>
	}

	return (
		<div id={"app"}>
			<div className="h-full bg-white dark:bg-gray-900">
				{ createInventory && <InventoryForm onClose={() => {setCreateInventory(false)}} />}
				{ createListing && <ListingForm onClose={() => {setListing(false)}} />}
				{ createCollection && <CollectionForm onClose={() => {setCollection(false)}} />}
				<Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50 lg:hidden">
					<div className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear"/>
					<div className="fixed inset-0 flex">
						<Dialog.Panel
							className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out">
							<Transition.Child>
								<div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out">
									<button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
										<span className="sr-only">Close sidebar</span>
										<XMarkIcon aria-hidden="true" className="h-6 w-6 text-white"/>
									</button>
								</div>
							</Transition.Child>
							<div className="flex grow flex-col gap-y-5 overflow-y-auto bg-indigo-600 px-6 pb-4">
								<div className="flex h-16 shrink-0 items-center">
									<img
										alt="Track and List"
										src={logo}
										className="h-8 w-auto"
									/>
								</div>
								<nav className="flex flex-1 flex-col">
									<ul role="list" className="flex flex-1 flex-col gap-y-7">
										<li>
											<ul role="list" className="-mx-2 space-y-1">
												{navigation.map((item) => (
													<li key={item.name}>
														<Link
															to={item.href}
															className={classNames(
																item.href === current
																	? 'bg-indigo-700 text-white'
																	: 'text-indigo-200 hover:bg-indigo-700 hover:text-white',
																'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
															)}
														>
															<item.icon
																aria-hidden="true"
																className={classNames(
																	item.href === current ? 'text-white' : 'text-indigo-200 group-hover:text-white',
																	'h-6 w-6 shrink-0',
																)}
															/>
															{item.name}
														</Link>
													</li>
												))}
											</ul>
										</li>
										<li className="mt-auto">
											<button
												onClick={handleAddProductClick}
												className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white"
											>
												<PlusCircleIcon
													aria-hidden="true"
													className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
												/>
												Add Products
											</button>
											<Link
												to="/settings"
												className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-indigo-700 hover:text-white"
											>
												<Cog6ToothIcon
													aria-hidden="true"
													className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
												/>
												Settings
											</Link>
										</li>
									</ul>
								</nav>
							</div>
						</Dialog.Panel>
					</div>
				</Dialog>
				<div
					className={`hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-${sidebarExpanded ? '72' : '20'} lg:flex-col transition-width duration-300`}>
					<div className="flex grow flex-col gap-y-5 overflow-y-auto bg-brightPurple px-6 pb-4">
						<div className="flex h-16 shrink-0 items-center">
							<img
								alt="Track and List"
								src={logo}
								className="h-8 w-auto grayscale"
							/>
						</div>
						<nav className="flex flex-1 flex-col">
							<ul role="list" className={`${sidebarExpanded ? "flex" : "inline-flex"} flex-1 flex-col gap-y-7`}>
								<li>
									<ul role="list" className="-mx-2 space-y-1">
										{navigation.map((item) => (
											<li key={item.name}>
												<Link
													to={item.href}
													className={classNames(
														item.href === current
															? 'bg-deepPurple text-white'
															: 'text-indigo-200 hover:bg-lavender hover:text-white',
														`group ${sidebarExpanded ? "flex" : "inline-flex"} gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`,
													)}
												>
													<item.icon
														aria-hidden="true"
														className={classNames(
															item.href === current ? 'text-white' : 'text-indigo-200 group-hover:text-white',
															'h-6 w-6 shrink-0',
														)}
													/>
													{sidebarExpanded && item.name}
												</Link>
											</li>
										))}
									</ul>
								</li>
								<li className="mt-auto">
									<section>
										<a
											onClick={handleAddProductClick}
											className={`group ${sidebarExpanded ? "flex" : "inline-flex"} -mx-2 cursor-pointer gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-lavender hover:text-white`}
										>
											<PlusCircleIcon
												aria-hidden="true"
												className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
											/>
											{sidebarExpanded && 'Add Products'}
										</a>
									</section>
									<section>
										<Link
											to="/settings"
											className={classNames(
												"/settings" === current
													? 'bg-deepPurple text-white'
													: 'text-indigo-200 hover:bg-lavender hover:text-white',
												`group -mx-2 ${sidebarExpanded ? "flex" : "inline-flex"} gap-x-3 rounded-md p-2 text-sm font-semibold leading-6`,
											)}
										>
											<Cog6ToothIcon
												aria-hidden="true"
												className={classNames(
													"/settings" === current ? 'text-white' : 'text-indigo-200 group-hover:text-white',
													'h-6 w-6 shrink-0',
												)}
											/>
											{sidebarExpanded && 'Settings'}
										</Link>
									</section>
								</li>
							</ul>
						</nav>
					</div>
					<button
						onClick={() => setSidebarExpanded(!sidebarExpanded)}
						className="absolute top-1/2 right-0 transform -translate-y-1/2 border hover:bg-white border-r-0 hover:text-deepPurple border-white p-2 rounded-l-md text-white"
					>
						{sidebarExpanded ? <ChevronLeftIcon className="h-6 w-6"/> : <ChevronRightIcon className="h-6 w-6"/>}
					</button>
				</div>
				<div className={`${sidebarExpanded ? 'lg:pl-60' : 'lg:pl-20'}`}>
					<div
						className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white dark:bg-gray-900 px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
						<button type="button" onClick={() => setSidebarOpen(true)}
						        className="-m-2.5 p-2.5 text-gray-700 dark:text-gray-300 lg:hidden">
							<span className="sr-only">Open sidebar</span>
							<Bars3Icon aria-hidden="true" className="h-6 w-6"/>
						</button>

						{/* Separator */}
						<div aria-hidden="true" className="h-6 w-px bg-gray-900/10 dark:bg-gray-700 lg:hidden"/>

						<div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
							<form action="#" method="GET" className="relative flex flex-1">
								<label htmlFor="search-field" className="sr-only">
									Search
								</label>
								<MagnifyingGlassIcon
									aria-hidden="true"
									className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400 dark:text-gray-500"
								/>
								<input
									id="search-field"
									name="search"
									type="search"
									placeholder="Search..."
									className="block outline-none focus:outline-none h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 dark:bg-gray-900 dark:text-gray-100 placeholder:text-gray-400 dark:placeholder:text-gray-500 focus:ring-0 sm:text-sm"
									onKeyDown={handleSearch}
								/>
							</form>
							<div className="flex items-center gap-x-4 lg:gap-x-6">
								{/* Profile dropdown */}
								<Menu as="div" className="relative">
									<Menu.Button className="-m-1.5 flex items-center p-1.5">
										<span className="sr-only">Open Notifications</span>
										<button type="button"
										        className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-400">
											<span className="sr-only">View notifications</span>
											<BellIcon aria-hidden="true" className={`h-6 w-6 ${notifications ? 'text-red-500' : ''}`}/>
										</button>
									</Menu.Button>
									<Transition
										as={React.Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										<Menu.Items
											className="absolute -right-16 md:-right-32 z-10 mt-2.5 w-48 md:w-60 lg:w-80 origin-top-right rounded-md bg-white dark:bg-gray-800 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
											<Menu.Item>
													<Link
														to={"/notifications"}
														className={classNames(
															'w-full text-deepPurple flex text-left px-3 py-1 text-sm leading-6 dark:text-gray-100'
														)}
													>
														<span className={"!text-deepPurple"}>All Notifications</span> &nbsp;
														<ArrowLongRightIcon aria-hidden="true" className={`h-6 w-6 text-deepPurple`}/>
													</Link>
											</Menu.Item>
											{notificationsStore.notifications.map((item) => (
												<Menu.Item key={item.id}>
													<Link
														to={`/notifications/${item.id}`}
														className={classNames(
															'block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-gray-100'
														)}
													>
														<p>{item.content}</p>
														<p>{item.timestamp}</p>
													</Link>
												</Menu.Item>
											))}
										</Menu.Items>
									</Transition>
								</Menu>
								<Link to={"/messages"} type="button"
								        className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-400">
									<span className="sr-only">View messages</span>
									<EnvelopeIcon aria-hidden="true" className={`h-6 w-6 ${messages ? 'text-red-500' : ''}`}/>
								</Link>
								<Link to={"/chats"} type="button"
								        className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500 dark:text-gray-300 dark:hover:text-gray-400">
									<span className="sr-only">View Chats</span>
									<ChatBubbleLeftRightIcon aria-hidden="true" className={`h-6 w-6 ${chats ? 'text-red-500' : ''}`}/>
								</Link>

								{/* Separator */}
								<div aria-hidden="true"
								     className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10 dark:bg-gray-700"/>

								{/* Profile dropdown */}
								<Menu as="div" className="relative">
									<Menu.Button className="-m-1.5 flex items-center p-1.5">
										<span className="sr-only">Open user menu</span>
										<span className="hidden lg:flex lg:items-center">
                      <span aria-hidden="true"
                            className="ml-4 text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
                        Account
                      </span>
                      <ChevronDownIcon aria-hidden="true" className="ml-2 h-5 w-5 text-gray-400 dark:text-gray-300"/>
                    </span>
									</Menu.Button>
									<Transition
										as={React.Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										<Menu.Items
											className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white dark:bg-gray-800 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
											{userNavigation.map((item) => (
												<Menu.Item key={item.name}>
													{({active}) => (
														<Link
															to={item.href}
															className={classNames(
																active ? 'bg-gray-50 dark:bg-gray-700' : '',
																'block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-gray-100'
															)}
														>
															{item.name}
														</Link>
													)}
												</Menu.Item>
											))}
											<Menu.Item>
												{({active}) => (
													<button
														onClick={() => {
															// Toggle theme logic here
															document.documentElement.classList.toggle('dark');
														}}
														className={classNames(
															active ? 'bg-gray-50 dark:bg-gray-700' : '',
															'block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900 dark:text-gray-100'
														)}
													>
														Change Theme
													</button>
												)}
											</Menu.Item>
										</Menu.Items>
									</Transition>
								</Menu>
							</div>
						</div>
					</div>
					<main className="py-10 w-full min-h-[calc(100vh)]">
						<div className="px-4 sm:px-6 text-gray-900 dark:text-white lg:px-8">
							{pageElements}
						</div>
					</main>
				</div>
			</div>
		</div>
	);
};

export default App;
