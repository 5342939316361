import React from 'react';
import noMessagesImage from "../images/no-messages.png";
import EmptyComponent from "../components/Empty";

const Chats = () => {
	const component = <EmptyComponent
		imageSrc={noMessagesImage}
		headerText={"Chats"}
		descriptionText={"This page contains the chats"}
	/>
	return (
		<div className="flex flex-col items-center justify-center min-h-screen rounded dark:bg-gray-900">
			{component}
		</div>
	);
};

export default Chats;
