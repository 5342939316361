import React from 'react';
import noInventoryImage from "../images/no-inventory-image.png";
import EmptyComponent from "../components/Empty";

const Inventory = ({
   handleCreateInventory,
   handleCreateCollection,
   handleCreateListing
 }: {
		handleCreateInventory: () => void;
		handleCreateCollection: () => void;
		handleCreateListing: () => void;
	}) => {

	const component = <EmptyComponent
		imageSrc={noInventoryImage}
		headerText={"Inventory"}
		descriptionText={"You have no inventory items"}
		actionText={"Create New Inventory"}
		onActionClick={handleCreateInventory}
	/>

	return (
		<div className="flex flex-col items-center justify-center min-h-screen rounded dark:bg-gray-900">
			{component}
		</div>
	);
};

export default Inventory;
