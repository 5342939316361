import React from 'react';
import noCategoryImage from "../images/no-categories-found.png";
import EmptyComponent from "../components/Empty";

const Categories = ({
	                    handleCreateInventory,
	                    handleCreateCollection,
	                    handleCreateListing
                    }: {
	handleCreateInventory: () => void;
	handleCreateCollection: () => void;
	handleCreateListing: () => void;
}) => {

	const component = <EmptyComponent
		imageSrc={noCategoryImage}
		headerText={"Categories"}
		descriptionText={"You have no recent categories"}
		actionText={"create new category"}
		onActionClick={handleCreateCollection}
	/>
	return (
		<div className="flex flex-col items-center justify-center min-h-screen rounded dark:bg-gray-900">
			{component}
		</div>
	);
};

export default Categories;
