import React from 'react';

const Homepage: React.FC = () => {
	return (
		<div className="flex flex-col items-center justify-center min-h-screen rounded dark:bg-gray-900">
			this is the home page and this one is coming soon.
		</div>
	);
};

export default Homepage;
