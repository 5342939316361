import React, { useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {Collection} from "./types";

const initialCollection:Collection = {
	id: '',
	user: '',
	name: '',
	listed: false,
	autoList: false,
	scheduleListing: 0,
	createdAt: Date.now(),
	updatedAt: Date.now(),
};

const CollectionForm = ({ initialData = initialCollection, onClose }: {onClose: () => void; initialData?: Collection}) => {
	const [collection, setCollection] = useState(initialData);
	const [loading, setLoading] = useState(false);
	const [showDialog, setShowDialog] = useState(true);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setCollection((prevCollection) => ({
			...prevCollection,
			[name]: value,
		}));
	};

	const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, checked } = e.target;
		setCollection((prevCollection) => ({
			...prevCollection,
			[name]: checked,
		}));
	};

	const handleFormSubmit = async () => {
		setLoading(true);
		try {
			// Simulate API call
			await new Promise((resolve) => setTimeout(resolve, 2000));
			console.log('Collection created:', collection);
			setShowDialog(false);
		} catch (error) {
			console.error('Error creating collection:', error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Transition appear show={showDialog} as={React.Fragment}>
			<Dialog as="div" className="relative z-10" onClose={() => {setShowDialog(false); onClose(); }}>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={React.Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
								<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
									Create Collection
								</Dialog.Title>
								<form onSubmit={(e) => { e.preventDefault(); handleFormSubmit(); }}>
									<div className="mt-4">
										<label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
											Name
										</label>
										<input
											type="text"
											name="name"
											id="name"
											value={collection.name}
											onChange={handleInputChange}
											className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
										/>
									</div>
									<div className="mt-4">
										<label htmlFor="user" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
											User
										</label>
										<input
											type="text"
											name="user"
											id="user"
											value={collection.user}
											onChange={handleInputChange}
											className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
										/>
									</div>
									<div className="mt-4 flex items-center">
										<input
											type="checkbox"
											name="listed"
											id="listed"
											checked={collection.listed}
											onChange={handleCheckboxChange}
											className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:text-white"
										/>
										<label htmlFor="listed" className="ml-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
											Listed
										</label>
									</div>
									<div className="mt-4 flex items-center">
										<input
											type="checkbox"
											name="autoList"
											id="autoList"
											checked={collection.autoList}
											onChange={handleCheckboxChange}
											className="h-4 w-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500 dark:bg-gray-700 dark:text-white"
										/>
										<label htmlFor="autoList" className="ml-2 block text-sm font-medium text-gray-700 dark:text-gray-300">
											Auto List
										</label>
									</div>
									<div className="mt-4">
										<label htmlFor="scheduleListing" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
											Schedule Listing (Timestamp)
										</label>
										<input
											type="number"
											name="scheduleListing"
											id="scheduleListing"
											value={collection.scheduleListing}
											onChange={handleInputChange}
											className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
										/>
									</div>
									<div className="mt-4 flex justify-end">
										<button
											type="submit"
											disabled={loading}
											className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
										>
											{loading ? (
												<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
													<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
													<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
												</svg>
											) : (
												'Create Collection'
											)}
										</button>
									</div>
								</form>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default CollectionForm;
