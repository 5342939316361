import React, {useState} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {Listing, MarketplacePlatform} from "./types";

const initialListing:Listing = {
	platform: MarketplacePlatform.EBAY,
	id: '',
	user: '',
	title: '',
	description: '',
	price: 0,
	currencyCode: '',
	quantity: 0,
	imageUrl: '',
	category: '',
	condition: '',
	seller: '',
	location: '',
	shipping: undefined,
	scheduleDateTime: 0,
	createdAt: Date.now(),
	updatedAt: Date.now(),
	status: '',
	inventoryId: '',
};

const ListingForm = ({ initialData = initialListing, onClose }: { initialData?: Listing, onClose: () => void;}) => {
	const [listing, setListing] = useState(initialData);
	const [loading, setLoading] = useState(false);
	const [showDialog, setShowDialog] = useState(true);

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setListing((prevListing) => ({
			...prevListing,
			[name]: value,
		}));
	};

	const handleFormSubmit = async () => {
		setLoading(true);
		try {
			// Simulate API call
			await new Promise((resolve) => setTimeout(resolve, 2000));
			console.log('Listing created:', listing);
			setShowDialog(false);
		} catch (error) {
			console.error('Error creating listing:', error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Transition appear show={showDialog} as={React.Fragment}>
			<Dialog as="div" className="relative z-10" onClose={() => {setShowDialog(false); onClose(); }}>
				<Transition.Child
					as={React.Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-black bg-opacity-25" />
				</Transition.Child>

				<div className="fixed inset-0 overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<Transition.Child
							as={React.Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white dark:bg-gray-800 p-6 text-left align-middle shadow-xl transition-all">
								<Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-white">
									Create Listing
								</Dialog.Title>
								<form onSubmit={(e) => { e.preventDefault(); handleFormSubmit(); }}>
									<div className="mt-4">
										<label htmlFor="marketplace" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
											Marketplace
										</label>
										<input
											type="text"
											name="marketplace"
											id="marketplace"
											value={listing.platform}
											onChange={handleInputChange}
											className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
										/>
									</div>
									<div className="mt-4">
										<label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
											Description
										</label>
										<textarea
											name="description"
											id="description"
											value={listing.description}
											onChange={handleInputChange}
											className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
										/>
									</div>
									<div className="mt-4">
										<label htmlFor="pricing" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
											Pricing
										</label>
										<input
											type="number"
											name="pricing"
											id="pricing"
											value={listing.price}
											onChange={handleInputChange}
											className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
										/>
									</div>
									<div className="mt-4">
										<label htmlFor="image" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
											Image URL
										</label>
										<input
											type="text"
											name="image"
											id="image"
											value={listing.imageUrl}
											onChange={handleInputChange}
											className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm dark:bg-gray-700 dark:text-white"
										/>
									</div>
									<div className="mt-4 flex justify-end">
										<button
											type="submit"
											disabled={loading}
											className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
										>
											{loading ? (
												<svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
													<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
													<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
												</svg>
											) : (
												'Create Listing'
											)}
										</button>
									</div>
								</form>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default ListingForm
